function isElementInViewport (el, offset = 0) {
    var rect = el.getBoundingClientRect();
    return (
        (rect.bottom + offset ) <= (window.innerHeight || document.documentElement.clientHeight)
    );
}

document.addEventListener("DOMContentLoaded", function() {

    const slide_1_container = document.getElementById('slide-1');
    const slide_2_container = document.getElementById('slide-2');
    const slide_3_container = document.getElementById('slide-3');
    const footer_container = document.getElementById('footer-container');    
    var last_offset = false;

    function writeLayout(){
        let scrollposition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        let slide_1_height = slide_1_container.offsetHeight;
        let footer_height = footer_container.offsetHeight;

        if(scrollposition > 0) {
            slide_1_container.classList.add('scrolled');
            slide_2_container.classList.add('scrolled');
            slide_3_container.classList.add('scrolled');
            slide_2_container.style.marginTop = slide_1_height + 'px';

            console.log(window.screen.availWidth);
            let isMobile = (window.screen.availWidth < 980) ? true : false;
            let scrollFactor = isMobile ? 0.25 : 0.5;
            let extra_offset = scrollposition * scrollFactor;
            //window.console.log(scrollposition + ' - ' + extra_offset);
            //console.log(isElementInViewport(slide_3_container, footer_height));
            if(!isElementInViewport(slide_3_container, footer_height)) {
                slide_3_container.style.marginTop = '-' + extra_offset + 'px';
            }

        } else {
            slide_1_container.classList.remove('scrolled');
            slide_2_container.classList.remove('scrolled');
            slide_3_container.classList.remove('scrolled');
            slide_2_container.style.marginTop = 0;
            slide_3_container.style.marginTop = 0;
        }

    }

    window.addEventListener('scroll', ()=> {
        writeLayout();
        //requestAnimationFrame(writeLayout);
    });

    window.addEventListener('resize', ()=> {
        writeLayout();
        //requestAnimationFrame(writeLayout);
    }, false);

});