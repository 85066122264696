import './styles.scss';
import './js/para.js';
import './js/slide-3.js';

function _calculateFactor() {
    document.documentElement.style.setProperty('--sizer', Math.min(Math.max((window.innerWidth / 1640), 0.48), 0.8) );
}

window.addEventListener('resize', _calculateFactor, false);
document.addEventListener('DOMContentLoaded', _calculateFactor, false);
window.addEventListener('load', _calculateFactor);


document.addEventListener("DOMContentLoaded", function() {
});